@use '@styles/theme.module' as *;

.navigation_wrapper {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .arrow_icon {
    color: $grayDarker;

    &:global(.Mui-disabled) {
      color: $grayLighter;
    }
  }

  .slide_counter_text {
    @include typographySmallBody;

    color: $grayDarker;
  }
}
