@use '@styles/theme.module' as *;

.alert {
  align-items: flex-start;

  @include media($from: $sm) {
    align-items: center;
  }

  :global(.MuiAlert-icon) {
    transform: translateY(spacing(1));

    @include media($from: $sm) {
      transform: translateY(spacing(0));
    }
  }

  :global(.MuiAlert-message) {
    width: 100%;
  }
}

.swiper {
  width: 100%;
  display: block;

  @include media($from: $sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.swiper-wrapper) {
    width: 100%;
    align-items: flex-start;

    @include media($from: $sm) {
      align-items: center;
      width: 80%;
    }
  }
}
