@use '@styles/theme.module' as *;

.alert_wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: spacing(6);
  width: 100%;
}

.alert_inner_wrapper {
  width: 100%;
  box-shadow: 0 2px 12px rgb(0 0 0 / 0.1);

  @include media($from: $md) {
    max-width: 728px;
  }
}
