@use '@styles/theme.module' as *;

.slide_text {
  @include typographyBody;

  width: 100%;
  word-break: break-word;

  @include media($from: $sm) {
    padding-right: spacing(13);
  }
}

.full_width {
  @include media($from: $sm) {
    &:first-of-type {
      padding-right: spacing(0);
    }
  }
}
