@use '@styles/theme.module.scss' as *;

$primary-for-svg: invert(38%) sepia(13%) saturate(2713%) hue-rotate(68deg) brightness(101%)
  contrast(89%);

.wrapper {
  align-items: flex-start;
  display: flex;
  padding: 2px 2px spacing(3) 2px;
  width: 100%;

  .show_more_items_button,
  .close_button {
    flex: 1;
    margin: 0 0 0 spacing(2);
    padding: spacing(3);

    @include media($from: $xl) {
      margin-top: spacing(1);
    }

    svg path {
      stroke: black;
    }
  }

  .show_more_items_button {
    @include media($until: $sm) {
      display: none;
    }
  }
}

.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include media($until: $sm) {
    overflow-x: auto;
  }

  width: 100%;

  @include media($from: $md) {
    justify-content: flex-end;
  }
}

.mobile_container {
  justify-content: flex-end;
}

.secondary_navigation_link {
  @include typographyBody;
  @include focusDashedBorderStyles;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: spacing(2) spacing(6);
  border: 1px solid $grayLight;
  border-radius: 2.5rem;
  transition: all 0.25s ease-out, visibility 0s;
  background: $white;
  color: $black;
  white-space: nowrap;
  word-wrap: normal;

  &:hover {
    border-color: $black;
  }
}

.with_icon {
  color: $primary;

  svg {
    width: 16px;
    height: 16px;
    margin-right: spacing(2);
    filter: $primary-for-svg;
    color: $black;
  }
}

.items {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  &.show_more_items {
    @include media($from: $sm) {
      flex-wrap: wrap;

      .shop_by_title,
      .item {
        margin-bottom: spacing(2);
      }
    }
  }

  .item {
    margin-right: spacing(2);
    align-self: center;

    &:last-of-type {
      margin-right: 0;
    }

    &.hide {
      @include media($from: $sm) {
        display: none;
      }
    }
  }
}

.express_enabled {
  background: $deliveryPassFG;
  color: $white;
  border: 1px solid $grayLighter;

  > svg {
    transform: scale(1.1);

    @include media($from: $xl) {
      transform: scale(1.2);
    }
  }
}

.shop_by_button {
  padding: spacing(2) spacing(5);
  box-shadow: #{shadow(2)};
  @include typographyButtonsAndLinksSmallMedium;

  &_express > svg {
    color: $white;
  }

  &:hover &_express > svg {
    color: $deliveryPassFG;
  }

  @include media($from: $lg) {
    padding: calc(#{spacing(3)} + #{spacing(1)} / 2) spacing(6);

    @include typographyButtonsAndLinksMedium;
  }

  &_text {
    margin-right: spacing(2);
  }

  &_express {
    @include media($until: $sm) {
      display: none;
    }

    margin-right: spacing(2);
  }

  &_arrow_icon {
    display: block;
    transform: rotate(90deg);

    @include media($from: $lg) {
      transform: rotate(0);
    }
  }
}

.shop_by_title {
  @include typographyBody;

  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: spacing(8);
}
