@use '@styles/theme.module.scss' as *;

.express_toggle {
  float: right;
  margin-top: spacing(3);
}

.module_list {
  margin-top: spacing(4);
}

.sticky_container {
  padding: spacing(5) 0 spacing(2);
}
