@use '@styles/theme.module' as *;

.spacer {
  &_top {
    padding-top: spacing(8);

    & + & {
      border-top: 1px solid $gray4;
    }
  }
}
