@use '@styles/theme.module.scss' as *;

.container {
  position: sticky;
  display: inline-block;
  top: 0;
  width: auto;
  left: 100%;
  z-index: 10;
}

.container.static_container {
  width: 100%;
}

.container.fullWidthBottomShadow {
  z-index: 1002;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 1px;
    left: 50%;
    width: 100vw;
    background-color: $white;
    box-shadow: 0 8px 12px 0 rgb(0 0 0 / 0.1);
    transform: translate3d(-50%, 0, 0);
  }
}

.container.leftNav_container {
  &::after {
    width: calc(100vw + 320px);
  }
}

.background {
  width: 100%;
  display: block;
  background-color: $white;
}
